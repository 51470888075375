import { Alchemy } from "~/components/logos/alchemy";
import { Caldera } from "~/components/logos/caldera";
import { CantonFull } from "~/components/logos/canton-full";
import { Coinflow } from "~/components/logos/coinflow";
import { CometCash } from "~/components/logos/comet-cash";
import { Conduit } from "~/components/logos/conduit";
import { DigitalAsset } from "~/components/logos/digital-asset";
import { EthereumClassicFull } from "~/components/logos/ethereum-classic-full";
import { Etherfuse } from "~/components/logos/etherfuse";
import { Fern } from "~/components/logos/fern";
import { GloDollar } from "~/components/logos/glo-dollar";
import { Meld } from "~/components/logos/meld";
import { Perena } from "~/components/logos/perena";
import { Rain } from "~/components/logos/rain";
import { StellarFull } from "~/components/logos/stellar-full";

const globalClasses = "max-h-7 w-auto object-contain";

export type EcosystemId =
  | "alchemy"
  | "caldera"
  | "canton-network"
  | "coinflow"
  | "comet-cash"
  | "conduit"
  | "digital-asset"
  | "ethereum-classic"
  | "etherfuse"
  | "fern"
  | "glo-dollar"
  | "meld"
  | "perena"
  | "rain"
  | "stellar";

type Props = Readonly<{
  ecosystemId: EcosystemId;
  className?: string;
}>;

export function EcosystemLogo({
  ecosystemId,
  className = "",
}: Props): JSX.Element | null {
  switch (ecosystemId) {
    case "alchemy":
      return <Alchemy />;
    case "caldera":
      return <Caldera className={className} />;
    case "canton-network":
      return <CantonFull className={`${globalClasses} ${className}`} />;
    case "coinflow":
      return <Coinflow className={`${globalClasses} ${className}`} />;
    case "comet-cash":
      return <CometCash className={`${globalClasses} ${className}`} />;
    case "conduit":
      return <Conduit className={className} />;
    case "digital-asset":
      return <DigitalAsset className={`${globalClasses} ${className}`} />;
    case "ethereum-classic":
      return (
        <EthereumClassicFull className={`${globalClasses} ${className}`} />
      );
    case "etherfuse":
      return <Etherfuse className={`${globalClasses} ${className}`} />;
    case "fern":
      return <Fern className={`${globalClasses} ${className}`} />;
    case "glo-dollar":
      return <GloDollar className={`${globalClasses} ${className}`} />;
    case "meld":
      return <Meld className={`${globalClasses} ${className}`} />;
    case "perena":
      return <Perena className={`${globalClasses} ${className}`} />;
    case "rain":
      return <Rain className={`${globalClasses} ${className}`} />;
    case "stellar":
      return <StellarFull className={`${globalClasses} ${className}`} />;

    default:
      return null;
  }
}
