type Props = Readonly<{
  className?: string;
}>;

export function Meld({ className }: Props): JSX.Element {
  return (
    <svg
      width={148}
      height={48}
      viewBox="0 0 148 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.904 48L8.48047 39.6746V29.8801L22.904 38.2054V48Z"
        fill="url(#paint0_linear_2439_22133)"
      />
      <path
        d="M22.904 48L37.327 39.6746V29.8801L22.904 38.2054V48Z"
        fill="url(#paint1_linear_2439_22133)"
      />
      <path
        d="M45.8128 8.3252V24.9826L37.327 29.8801L37.3264 13.2224L45.8128 8.3252Z"
        fill="url(#paint2_linear_2439_22133)"
      />
      <path
        d="M45.8128 8.3252L31.3898 0L22.9034 4.89728L37.3264 13.2224L45.8128 8.3252Z"
        fill="url(#paint3_linear_2439_22133)"
      />
      <path
        d="M0 8.32538L14.4235 0L22.9034 4.89728L8.47967 13.2227L0 8.32538Z"
        fill="#F969D3"
      />
      <path
        d="M0 8.32538V24.9826L8.48047 29.8801L8.47967 13.2227L0 8.32538Z"
        fill="url(#paint4_linear_2439_22133)"
      />
      <path
        d="M90.5076 22.206C90.6789 22.6051 91.2805 24.4332 91.2805 24.7427V37.689H86.6082V25.1321C86.6082 25.0464 86.1039 23.9718 85.993 23.8004C84.636 21.7057 80.9877 21.7096 79.6308 23.8004C79.5198 23.9718 79.0156 25.0464 79.0156 25.1321V37.689H74.3433V25.9108C74.3433 25.5097 73.8371 24.1606 73.5665 23.7673C72.0635 21.5908 68.1446 21.7582 66.9765 24.1898C66.887 24.3748 66.556 25.2216 66.556 25.3267V37.689H61.8837V25.1321C61.8837 24.5208 62.4677 22.7394 62.7558 22.1106C65.1894 16.788 73.4867 16.4571 76.5821 21.3338C79.7067 16.4571 88.1734 16.7939 90.5076 22.208V22.206ZM147.738 10.0443V28.247C147.738 40.4632 129.261 41.8026 127.152 29.9485C125.622 21.3475 133.861 14.7361 141.493 19.5019L143.068 20.9445V10.0443H147.74H147.738ZM136.007 22.3559C130.236 23.269 130.211 33.0264 136.371 33.774C145.373 34.8661 145.242 20.8958 136.007 22.3559ZM115.032 29.7071H99.2624C99.8426 33.6221 104.943 35.1523 108.089 33.1802C108.426 32.968 109.211 32.0432 109.483 32.0432H114.058C112.631 36.8343 107.373 38.7305 102.724 38.0258C91.8042 36.369 91.4246 20.2981 102.368 18.1138C109.892 16.6109 115.744 22.1554 115.034 29.709L115.032 29.7071ZM109.97 26.0081C108.917 20.7985 100.444 20.7985 99.4571 26.0081H109.97ZM118.731 37.689H123.111L123.403 37.397V10.0443H118.731V37.689Z"
        className="fill-[#0c112a] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2439_22133"
          x1="40.9172"
          y1="47.7747"
          x2="12.5146"
          y2="-57.1836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E98ADA" />
          <stop offset="1" stopColor="#7E4DBD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2439_22133"
          x1="22.9064"
          y1="0"
          x2="22.9064"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#719DED" />
          <stop offset="1" stopColor="#2545BE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2439_22133"
          x1="22.9061"
          y1="0"
          x2="22.9061"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93EBFF" />
          <stop offset="1" stopColor="#197DDB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2439_22133"
          x1="22.9064"
          y1="-2.05771e-06"
          x2="63.3292"
          y2="39.6505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F969D3" />
          <stop offset="1" stopColor="#4F51C0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2439_22133"
          x1="20.8061"
          y1="50.6934"
          x2="-8.11339"
          y2="17.4295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E98ADA" />
          <stop offset="1" stopColor="#7E4DBD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
